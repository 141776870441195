import _, { compose } from 'underscore';
import utils from '@/utils/utils';
import store from '@/store/store.js';
import Vue from 'vue';
import VueRouter from 'vue-router';
import router from './router/router.js';
import snapshotApi from './api/snapshot.js';
import Message from 'kpc/components/message';
import filters from '@/filters/filter';
import api from '@/api/index.js';
import App from './App.vue';
import {_$} from 'kpc';
import loading from './components/loading.js';
import {localize} from 'kpc';

// import * as Sentry from '@sentry/browser';
// import * as Integrations from '@sentry/integrations';

window.utils = utils;
window._ = _;
window._$ = _$;

Vue.prototype.$Message = Message;
//loading
Vue.use(loading);

//KPC国际化
Vue.prototype._$ = _$;
Vue.prototype._ = _;

Vue.use(VueRouter);

Vue.config.productionTip = false;
Vue.prototype.api = api;
Vue.prototype.filter = filters;
Vue.prototype.utils = utils;
Vue.prototype.$store = store;

utils.getUserInfo();


Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
  utils.docClose();
});
window.addEventListener('message', function (event) {
        // 判断消息来源
        if(event.data.from === 'kec'){
            if(event.data.close === 'closeHelpDocument'){
                utils.docClose();
            }
        }
}, false);
router.beforeEach((to, from, next) => {
    if(to.name === 'snapshotList'){
        snapshotApi.ValidateSnapshotService({}).then(res => {
            if(res.status){
                if(res.data.serviceCheck === 'active'){
                    next();
                }else if (res.data.serviceCheck === 'disable'){
                    window.parent.postMessage({from: 'ebs', menu:false},'*');
                    next({
                        path: '/snapshot/unService'
                    });
                } else if(res.data.serviceCheck === 'debtShutdown'){
                    Message.error(_$('您的账号已欠费，请先充值'),3000);
                }else if(res.data.serviceCheck === 'forceShutdown'){
                    Message.error(_$('您的快照服务已关停，如需开通请联系金山云客服'),3000);
                }
            }
            
        });
    }else{
        next();
    }
  });
  
  router.onError((error) => {
    const pattern = /Loading chunk (\d)+ failed/g;
    const isChunkLoadFailed = error.message.match(pattern);
    const targetPath = router.history.pending.fullPath;
    if (isChunkLoadFailed) {
    //   router.replace(targetPath);

    }
  });



function getCookie(name) {
    let result = null;
      let reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
      let arr = document.cookie.match(reg);
      if (arr) {
          result = unescape(arr[2]);
      }
    return result;
}

let mainVue = new Vue({
    router,
    render: h => h(App),
    store
  });

//   global.Vue = mainVue;


let isRefresh = true;

window.__ksStorage__.addLangListener({
	name: 'ksc-ebs20220614104450',
	arg: 'KSC_LANG',
	async callback(data) {
        let languageMap = {};
		let lang = getCookie('ksc_lang');
        if (lang === 'en') {
            let i18n = (await import('kpc/i18n/en-US')).default;
            languageMap = {
                ...i18n,
                ...data
            };
        }
        localize(languageMap);
		// 记录当前语言环境
		Vue.prototype._lang = lang;
		//通过uss tool完成
		if (isRefresh) {
			isRefresh = false;
			document.title = mainVue._$('云服务器');
			mainVue.$mount('#app'); //获取国际化数据后挂载mainVue
			global.Vue = mainVue;
		} else {
			window.location.reload();
		}
        require('./utils/formRules');
	}
});

//npm install @sentry/browser
//npm install @sentry/integrations

// process.env.NODE_ENV === 'production' && 
// Sentry.init({
//   dsn: 'http://20363f26e5924a57beaf64997954fd8b@ebs.console.ksyun.com/6',
//   integrations: [
//     new Integrations.Vue({
//       Vue,
//       attachProps: true
//     })
//   ],
//   release: process.env.VUE_APP_RELEASE_VERSION,
//   environment: process.env.VUE_APP_SERVERKEY,
//   debug: true
// });


  export default mainVue;