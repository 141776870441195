import Vue from 'vue';
import Vuex from 'vuex';
import commonApi from '@/api/commonApi';
Vue.use(Vuex);

const state = {
    commonConfig:{
        copySnapshotLimit: 10,
        copySnapshotRegion: {},
        setdeleteTimeSnapshotLimit: 10,
        'helpDocument': {
            '云硬盘使用指南': '//docs.ksyun.com/documents/1046',
            '快照使用指南': '//docs.ksyun.com/documents/2684',
            'createSnapshot': '//docs.ksyun.com/documents/2684',
            'recycleBin': '//docs.ksyun.com/documents/1055',
            'upgradeCreateSnapshot': '//docs.ksyun.com/documents/5266',
            'upgradeLinux': '//docs.ksyun.com/documents/39926',
            'upgradeWindow': '//docs.ksyun.com/documents/39927',
            'upgradeCareful': '//docs.ksyun.com/documents/39865',
            'snapshotPrice': '//docs.ksyun.com/documents/37052',
            'snapshotAgreement': '//docs.ksyun.com/documents/2737',
            'snapshotBilling': '//sw.ksyun.com/pro/calc/#/oth/37393/doc',
            'essdHelp': '//docs.ksyun.com/documents/2676'
        }
    }
  };
  const getters = {
    commonConfig(state) {
        return state.commonConfig;
    }
  };
  const mutations = {
    setCommonConfig(state, value) {
        state.commonConfig = value;
    }
  };
  const actions = {
    async getQueryCommonConfig(context){
        await commonApi.QueryCommonConfig().then(res => {
            if(res.status){
                context.commit('setCommonConfig', JSON.parse(res.data.Result));
            } else{
                utils.showErrorTip(res);
            }
            actions.describeSnapshotCopyMax();
        }).catch(err => {
            utils.showErrorTip(err);
            actions.describeSnapshotCopyMax();
        });
    },
    async describeSnapshotCopyMax(){
        await commonApi.DescribeSnapshotCopyMax().then(res => {
            if(res.status && res.data && res.data.Max){
                state.commonConfig.copySnapshotLimit = res.data.Max;
            }
        });
    },
    async initRegionList(){
        await commonApi.QuerySnapshotCopyRegionLimits().then(res => {
            if(res.status){
                let regionList =JSON.parse(res.data.Result);
                state.commonConfig.copySnapshotRegion = regionList;
            }
        });
    }
  };
  
export default new Vuex.Store({
    state,
    getters,
    mutations,
    actions
  });
  
