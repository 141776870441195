import {_$} from 'kpc/components/utils';
import Message from 'kpc/components/message';
import download from '../../scripts/download';
import filters from '../filters/filter';
let version = '';
let utils = {
    /**
     * 生成一个map函数，通过key获取对应的值，如果没有则返回原key
     */
    keyMap: function (map) {
        return function (key) {
            if (!arguments.length) {
                return map;
            } 
            return map[key] || key;
        };
    },

    //对集合去重 [{a:1}, {a:2}, {a:1}]
    uniqByKey: function (ary, key) {
        let obj = {};
        _.each(ary, item => {
            if (!obj[item[key]]) {
                obj[item[key]] = item;
            }
        });
        return _(obj).value();
    },

    //判断对象是含有可枚举属性
    isEmpty(obj){
        let flag = true;
        for(let pro in obj) {
            if(obj[pro]){
                flag = false;
            }
        }
        return flag;
    },
    
    //公共处理region
    unRegions(productTypeList){
        // let productRegions = _. map(productTypeList, 'regionList');
        let productRegions =  _.flatten(_.map(productTypeList, 'regionList'));
        let obj = {};
        productRegions = utils.deepCopy(productRegions);
        _.each(productRegions, region => {
            let azList = region.azList;
            let key = region.regionCode;
            if (obj[key]) {
                obj[key].azList = utils.unionAZ(obj[key].azList.concat(azList));
            } else {
                obj[key] = region;
            }
        });
        return _.values(obj);
    },

    // //传递region列表给主机
    // initRegionList(regionsList){
    //     if(self !== top){
    //         window.parent.postMessage({from: 'ebs', regionsList},'*');
    //     }else{
    //     }
    // },
    
    //提取可用区
    unionAZ(azList) {
        let obj = {};
        _.each(azList, function (az) {
            let key = az.azCode;
            if (!obj[key]) {
                obj[key] = az;
            }
        });
        return _.values(obj);
    },

    //
    deepCopy(array) {
        let ary = [];
        _.each(array, function (item) {
            let obj = {};
            for (let attr in item) {
                obj[attr] = item[attr];
            }
            ary.push(obj);
        });

        return ary;
    },

    //到期时间
    transformEndTime: function (timeString, num) {
        num ? undefined : (num = 7);
        if (timeString) {
            let dis = new Date(timeString) - new Date();
            let diffDay = Math.floor(dis / (24 * 3600 * 1000));
            if (-1 < diffDay && diffDay < 0) {
                return {
                    showIcon: true,
                    text: utils.countTime(timeString, 'xufei'),
                    color: 'status-error',
                    overdue: true
                };
            }else if(-7 <= diffDay && diffDay <= -1){
                let time = 7 +  Math.floor(diffDay);
                return {
                    showIcon: true,
                    text:  _$('剩余') + time + _$('天续费'),
                    color: 'status-error',
                    overdue: true
                };
            }else if( diffDay < -7 ){
                let time = 7 +  Math.floor(diffDay);
                return {
                    showIcon: true,
                    text:  _$('已过期'),
                    color: 'status-error',
                    overdue: true
                };
            }else if (0 <= diffDay < 1) {
                return {
                    showIcon: true,
                    text: utils.countTime(timeString),
                    color: 'status-attaching',
                    overdue: false
                };
            } else if (diffDay >= 1 && diffDay <= num) {
                return {
                    showIcon: true,
                    text: _$('剩余') + Math.floor(diffDay) + _$('天到期'),
                    color: 'status-attaching',
                    overdue: false
                };
            }
            return {
                showIcon: false,
                text: utils.formatTimestamp(Number(timeString)),
                color: '',
                overdue: false
            };
        }
        return {
            showIcon: false,
            text: '--',
            color: '',
            overdue: false
        };
    },

    countTime: function (closeTime, type) {
        if (closeTime === 0 || closeTime < 0) return;
        let endDate = new Date(closeTime);
        let leftTime = type === 'xufei' ? new Date().getTime() - endDate.getTime(): endDate.getTime() - new Date().getTime();
        let leftsecond = parseInt(leftTime / 1000);
        let day1 = Math.floor(leftsecond / (60 * 60 * 24));
        let hour = Math.floor((leftsecond - day1 * 24 * 60 * 60) / 3600);
        // let minute = Math.floor((leftsecond - day1 * 24 * 60 * 60 - hour * 3600) / 60);
        // let second = Math.floor(leftsecond - day1 * 24 * 60 * 60 - hour * 3600 - minute * 60);
        let xefeitime =  24 - hour;
        let res =  type === 'xufei' ? _$('剩余') + xefeitime + _$('小时续费') : _$('剩余') + hour + _$('小时后到期');
        return res;
    },
    strPad(str, length, pad) {
        str = str.toString();
        pad = pad === undefined ? '0' : pad;
        let l = str.length;
        if (l < length) {
            str = new Array(length - l + 1).join(pad) + str;
        }
        return str;
    },
    formatTimestamp(timestamp) {
        if (!timestamp) return;
        timestamp = utils.ctrlTimeStamp(timestamp);
        let date = new Date(timestamp),
            strPad = utils.strPad;
        let Y = date.getFullYear(),
            M = strPad(date.getMonth() + 1, 2),
            D = strPad(date.getDate(), 2),
            h = strPad(date.getHours(), 2),
            m = strPad(date.getMinutes(), 2),
            s = strPad(date.getSeconds(), 2);
        return [Y, M, D].join('-') + ' ' + [h, m, s].join(':');
    }, 
    ctrlTimeStamp(timestamp) {
        if (typeof timestamp === 'string') {
            timestamp = timestamp.replace('+0800', '').replace(/-/g, '/').replace('T', ' ');
        }
        return timestamp;
    },

    errorvalueMap(key){
        let map = {
            InvalidAuthenticationCode: '无权限访问该资源',
            OperationForbidden: '该卷属于其他资源',
            InternalError: '机房不可用或未知错误',
            UserTokenEmpty: '登陆账号信息失效，请重新登陆',
            'Payment.CalculateProductFailed': '计算价格异常',
            'Payment.CreateProductFailed': '创建商品异常',
            'Payment.QueryOrderFailed': '查询订单信息异常',
            'Payment.QueryProductsByProductIdsFailed': '查询商品信息异常',
            'Payment.QuerySubOrdersByInstanceIdsFailed': '查询子订单信息异常',
            QueryTrialWalletFailed: '查询试用额度失败',
            'BillType Invalid': '计费类型无效',
            QueryEbsListFailed: '查询价格体系失败',
            'Region Invalid': '机房无效',
            'Parameter Invalid': '参数无效',
            'Duration Invalid': '选择的时长无效',
            'InstanceId Invalid': '请选择要挂载的主机实例',
            'Size Invalid': '硬盘容量无效',
            'Size or BillType or ProductType Invalid': '硬盘容量、计费类型或商品类型无效',
            'ProductWhat Invalid': '商品性质无效',
            'RenewalProduct Invalid': '创建续费商品失败',
            UpdateFailed: '创建扩容商品失败',
            'ProductName can not be empty': '硬盘名称不能为空',
            'ProductName Invalid': '硬盘名称无效',
            'ProductType can not be null': '商品类型不能为空',
            'ProductType Invalid': '无效的商品类型',
            'Size can not be null': '硬盘容量不可为空',
            'BillType can not be null': '计费类型不可为空',
            'Count can not be null': '硬盘数量不可为空',
            'Count Invalid': '硬盘数量无效',
            'InstanceNameSuffix Invalid': '硬盘名称后缀名无效',
            'VolumeId can not be empty': '硬盘ID不可为空',
            'VolumeId Invalid': '硬盘ID无效',
            'SnapshotId Invalid': '快照ID无效',
            'VolumeName can not be empty': '硬盘名称不可为空',
            'VolumeName Invalid': '硬盘名称无效',
            'InstanceId can not be empty': '硬盘ID不可为空',
            ProjectMemberNotExist: '该用户不是所选项目组成员',
            NotExistProjectId: '项目组不存在',
            QueryEBSPriceFailed: '查询新价格体系出错',
            'ProductType Sold Out': '该硬盘类型售罄',
            InvalidAuthentication: '当前子账号无删除权限',
            CreateOrderFailedWithNotSufficientFunds: '当前账号余额不足，请先充值后再开通快照服务',
            SnapshotInAssembledImage: '删除失败！所选快照有关联的镜像，请先删除镜像后再重试',
            CreateSnapshotFailedWithAvailabilityZoneInvalid: '当前区域升级中，暂不支持此操作',
            SnapshotAvailabilityZoneInvalid: '当前区域升级中，暂不支持此操作',
            RollbackSnapshotFailedWithAvailabilityZoneInvalid: '当前区域升级中，暂不支持此操作',
            OperationFailedWithBrokenVolume: '当前云盘数据异常，暂不支持此操作'
        };
        return map[key] || false;
    },

    //处理错误信息
    showErrorTip(err){
        if(err.data === 'sameRequest'){return;}
        if(!_.isEmpty(err)&& !_.isEmpty(err.data) && err.data.Error.Code){
            let errData = err.data;
            if (errData.Error.Code === 'UserTokenEmpty') {
                window.location.href = '//passport.ksyun.com/login.html';
                return;
            } 
            if(utils.errorvalueMap(errData.Error.Code)){
                let errormessage = errData.Error.Message;
                    errormessage = errormessage + '';
                    if (errData.Error.Code === 'GetFromTagV2OpenAPI') {
                        if(errormessage.indexOf('InvalidAuthenticationCode') !== -1 || errormessage.indexOf('timed out') !== -1) {
                            errormessage.indexOf('InvalidAuthenticationCode') !== -1  ? Message.error(_$('用户缺少标签操作权限'),3000) : Message.error(_$('请求超时，请重试'),3000);
                        } else{
                            Message.error(errormessage,3000);
                        }
                        return;
                    }
                Message.error(_$(utils.errorvalueMap(errData.Error.Code)),3000);
            }else{
                errData.Error.Message ? Message.error(errData.Error.Message) : Message.error(_$('请求失败'),3000);
            }
        } else{
            Message.error(_$('请求失败'),3000);
        }
    },

    //导出
    download(dataString, name) {
        download(
            '\ufeff' + dataString,
            name + '-' + utils.namePostfix() + '.csv',
            'text/csv;charset=utf8;'
        );
    },

    namePostfix() {
        let date = this.formatTimestamp(new Date().getTime());
        return date.replace(/(:|-| )/g, '');
    },

    //utc转换为日期格式
    utcChangetime(date) {
        let y = date.getFullYear();
        let m = date.getMonth() + 1;
        m = m < 10 ? '0' + m : m;
        let d = date.getDate();
        d = d < 10 ? '0' + d : d;
        let h = date.getHours();
        h = h < 10 ? '0' + h : h;
        let minute = date.getMinutes();
        minute = minute < 10 ? '0' + minute : minute;
        // let second = date.getSeconds();
        // second = second < 10 ? '0' + second : second;
        return y + '-' + m + '-' + d + ' ' + h + ':' + minute;
    },

    nextStr : '',

    changeDataType(obj) {
        let str = '';
        if (typeof obj === 'object') {
          for (let i in obj) {
            if (typeof obj[i] !== 'object') {
              str += i + '=' + obj[i] + '&';
            } else if (typeof obj[i] === 'object') {
                utils.nextStr = '';
              str += utils.changeSonType(i, obj[i]);
            }
          }
        }
        return str.replace(/&$/g, '');
    },

    changeSonType(objName, objValue) {
        if (typeof objValue === 'object') {
          for (let i in objValue) {
            if (typeof objValue[i] !== 'object') {
              let value = objName + '[' + i + ']=' + objValue[i];
              utils.nextStr += encodeURI(value) + '&';
            } else {
                utils.changeSonType(objName + '[' + i + ']', objValue[i]);
            }
          }
        }
        return utils.nextStr;
    },

    //排序日期
    sortAutoSnapshotDate(arr){
        let newArr = _.map(arr, function(num){ return Number(num); });
        newArr = newArr.sort(utils.compare);
        let datalist = [];
        newArr.forEach(element => {
            datalist.push(filters.data(element));
        });
        return datalist.join(',');
    },

    setLocalStorage(key) {
        __ksStorage__.setValue('region', key);
        localStorage.region = key;
    },

    getLocalStorageRegion() {
        __ksStorage__.getValue('region', function (name, value) {
            localStorage.region = value;
        });
        return localStorage.region;
    },

    //打开弹窗要禁用公用region组件
    openDialogPostMeesage(flag){
        window.parent.postMessage({
            from: 'ebs',
            regionDisabled:flag,
            regionList: JSON.parse(window.localStorage.getItem('regions')),
            region: utils.getLocalStorageRegion(),
            openMaskLayer: flag
        },'*');
    },

    initRegion(regionsList){
        let asia = [];
        let Chinese = [];
        let European = [];
        regionsList.forEach(element => {
            if (element.areaCode === 'AP') {
                asia.push(element);
                asia.areaName = element.areaName;
                asia.areaEnName = element.areaEnName;
            }
            if (element.areaCode === 'CN') {
                Chinese.push(element);
                Chinese.areaName = element.areaName;
                Chinese.areaEnName = element.areaEnName;
            }
            if (element.areaCode === 'EU') {
                European.push(element);
                European.areaName = element.areaName;
                European.areaEnName = element.areaEnName;
            }
        });
        return {
            asia: asia,
            Chinese: Chinese,
            European: European
        };
    },

    compare(x, y) {//比较函数
        if (x < y) {
            return -1;
        } else if (x > y) {
            return 1;
        } 
        return 0;
    },

     //控制台帮助文档浮层界面
     gethelpDocument(chineseUrl) {
        window.parent.postMessage({from: 'ebs', close: 'closeHelpDocument'},'*');
        __ksStorage__.docSet({
            indexUrl: chineseUrl,
            indexEnUrl: _$(chineseUrl),
            zIndex: 19999
        });
    },

    //关闭控制台帮助文档浮层界面
    docClose(){
        __ksStorage__.docSet({
            zIndex: -20
        });
        __ksStorage__.docClose();
    },

    goOutConsole(url, kecLocalDisk, id, volume){
        kecLocalDisk === 'instanceDetail' ? 
        window.parent.postMessage({from: 'ebs', to: url, kecFrom: 'instanceDetail', Region: utils.getLocalStorageRegion(), InstanceId:id, VolumeId: volume},'*')
        :window.parent.postMessage({from: 'ebs', to: url, Region: utils.getLocalStorageRegion()},'*');
    },

    defer() {
        const deferred = {};
        const promise = new Promise((resolve, reject) => {
        deferred.resolve = resolve;
        deferred.reject = reject;
        });
    
        deferred.promise = () => {
            return promise;
        };
    
        return deferred;
    },
    getUserInfo() {
        const defer = utils.defer();
        __ksStorage__.getBaseInfo('userInfo', function (oData) {
            if (oData) {
                // 做兼容
                let data = _.clone(oData);
                _.extend(data, data.user || data.sub_user);
                window.localStorage.setItem('userId',data.userId);
            }
            return defer.resolve();
        });
        return defer.promise();
    },
    //判断传入时间是否大于当前时间超过一小时
    timeOverHour(deleteTime) {
        deleteTime = new Date(deleteTime).getTime();
        let nowTime = new Date().getTime() + '';
        nowTime = Number(nowTime.slice(0, nowTime.length - 4) + '0000');
        return deleteTime - nowTime >= 3600000;
    },
    
    DateAdd(interval,number,date) {  
        switch(interval) {  
            case   'y'   :   {  
                    date.setFullYear(date.getFullYear()+number);  
                    return   date;  
                    break;  
            }  
            case   'm'   :   {  
                    date.setMonth(date.getMonth()+number);  
                    return   date;  
                    break;  
            }  
            case   'd'   :   {  
                    date.setDate(date.getDate()+number);  
                    return   date;  
                    break;  
            }  
            default   :   {  
                    date.setDate(d.getDate()+number);  
                    return   date;  
                    break;  
            }  
        }
    },

    //计算时间差值
    calculationTime(time1, time2){
        time1 = new Date(time1);    //转换为中国标准时间
        time2 = new Date(time2);
        time1 = time1.getTime();    //转换为时间戳
        time2 = time2.getTime();
        if(time1 > time2){return 0;}
        let runTime = (time2 - time1) / 1000;       //开始得出时间差,然后计算
        let year = Math.floor(runTime / 86400 / 365) > 0 ? Math.floor(runTime / 86400 / 365) : 0;       
        runTime = runTime % (86400 * 365);
        let month = Math.floor(runTime / 86400 / 30) > 0 ? Math.floor(runTime / 86400 / 30) : 0;
        runTime = runTime % (86400 * 30);
        let day = Math.floor(runTime / 86400) > 0 ? 1 : 0;
        runTime = runTime % 86400;
        return year * 12 + month + day; 
    },
    
    getFormatDate() {
        let date = new Date();
        let month = date.getMonth() + 1;
        let strDate = date.getDate();
        if (month >= 1 && month <= 9) {
            month = '0' + month;
        }
        if (strDate >= 0 && strDate <= 9) {
            strDate = '0' + strDate;
        }
        let currentDate = date.getFullYear() + '-' + month + '-' + strDate
                + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
        return currentDate;
    },
    //带宽换算
    formatSizeUnits(x) {
        let units = ['bytes', 'KB', 'MB'];
        if (!x) {
            return 0;
        }
        let l = 0,
            n = parseInt(x, 10) || 0;
        // while (n >= 1024 && ++l) {
        //     n = n / 1024;
        // }
        if (n >= 1024) {
            n = n / 1024;
            ++l;
        }
        if (n >= 1024) {
            n = n / 1024;
            ++l;
        }
        return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l] + '/s';
    },
    getLang(){
        let arr = [];
        let lang = 'zh';
        document.cookie.length > 0 ? arr = document.cookie.split(';') : '';
        if(arr.length > 0){
            let obj = '';
            arr.forEach(element => {
                if(element.indexOf('ksc_lang') > -1){
                    obj = element;
                }
            });
            lang = obj && obj.indexOf('en') > -1 ? 'en' : 'zh';
        }
        return lang;
    },
    changeTwoDecimal(x) {
        let fx = parseFloat(x);
        if (isNaN(fx)) {
            return false;
        }
        fx = Math.round(x * 10) / 10;
        let sx = fx.toString();
        let posdecimal = sx.indexOf('.');
        if (posdecimal < 0) {
            posdecimal = sx.length;
            sx += '.';
        }
        while (sx.length <= posdecimal + 1) {
            sx += '0';
        }
        return sx;
    },
    ESSDQuotasMap(value, decEbsQuotas, diskSizeRange) {
        let byte = 0;
        let iops = 0;
        if (!_.isEmpty(decEbsQuotas)) {
            if(!_.isEmpty(decEbsQuotas[value])){
                byte =
                decEbsQuotas[value].ByteAll.Max <
                decEbsQuotas[value].ByteAll.Step * diskSizeRange + decEbsQuotas[value].ByteAll.Min
                    ? decEbsQuotas[value].ByteAll.Max
                    : decEbsQuotas[value].ByteAll.Step * diskSizeRange +
                      decEbsQuotas[value].ByteAll.Min;
                iops =
                decEbsQuotas[value].iopsAll.Max <
                decEbsQuotas[value].iopsAll.Step * diskSizeRange + decEbsQuotas[value].iopsAll.Min
                    ? decEbsQuotas[value].iopsAll.Max
                    : decEbsQuotas[value].iopsAll.Step * diskSizeRange +
                      decEbsQuotas[value].iopsAll.Min;
            }
        }
        return value + _$('(单盘性能上限IOPS:') + iops / 10000 + _$('万 吞吐量:') + byte + 'MB/s)';
    },

    resolveProgressTypeChange(item){
        if(item.UpgradeVolumeTypeTaskProcess.expected_time_remaining){
            return _$('预计还剩') + utils.minutesToHours(item.UpgradeVolumeTypeTaskProcess.expected_time_remaining);
        }
            return false;
        
    },

    minutesToHours(minutes) {
        let hours = Math.floor(minutes / 60);
        let remainingMinutes = minutes % 60;
        return hours ? hours + 'h ' + remainingMinutes + 'min' : remainingMinutes + 'min' ;
    },

    intersectionBy(arrays, property) {
        console.log(arrays);
        // 如果没有传递数组或数组为空，返回空数组
        if (!arrays || arrays.length === 0) {
          return [];
        }
      
        // 将第一个数组作为基准
        const baseArray = arrays[0];
      
        // 使用 filter 方法对基准数组进行过滤，只保留在其他数组中也存在的元素
        const result = baseArray.filter((baseElement) => {
          // 获取基准元素的属性值
          const baseValue = baseElement[property];
      
          // 检查其他数组中是否存在具有相同属性值的元素
          return arrays.slice(1).every((array) => {
            return array.some((element) => element[property] === baseValue);
          });
        });
      
        return result;
      }
    
};
export default utils;

