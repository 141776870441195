import Axios from 'axios';
const axios = Axios.create();
import uuidv4 from 'uuid/v4';
// import * as Sentry from '@sentry/browser';
import utils from '../utils/utils';
//请求超时时间
axios.defaults.timeout = 90000;

let CancelToken = Axios.CancelToken;
// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/json;';
// axios.defaults.headers.post['Accept-Language'] = utils.getLang() === 'en' ? 'en-us' : 'zh-CN';
let cancelList = [];

// 取消重复请求
const pending = [];

// 移除重复请求
const removePending = (config) => {
    for (const key in pending) {
      const item = +key; // 转number类型
      const list = pending[key];
      // 当前请求在数组中存在时执行函数体
      /**
       * 过滤需要重复调用的接口
       */
      const filterList = [];
      if (
        !filterList.includes(config.url) &&
        list.url === config.url &&
        list.method === config.method &&
        config.url.indexOf('Action=DescribeEbsQuotas') === -1 &&
        config.url.indexOf('Action=DescribeVolumes') === -1 &&
        config.url.indexOf('Action=ListTagValues') === -1 &&
        config.url.indexOf('Action=GetEBSPriceInfo') === -1
      ) {
        // 执行取消操作
        list.cancel('sameRequest');
        // 从数组中移除记录
        pending.splice(item, 1);
      }
    }
  };
  
    
// 请求拦截器
axios.interceptors.request.use(
    config => {
        removePending(config);
        config.cancelToken = new CancelToken((c) => {
          pending.push({
            url: config.url,
            method: config.method,
            params: config.params,
            data: config.data,
            cancel: c
          });
        });
        return config;
    },
    error => {
        return Promise.error(error);
    }
);

// 响应拦截器
axios.interceptors.response.use(
    (response) => {
      removePending(response.config);
      return response;
    },
    (error) => {
      const response = error.response;
      // eslint-disable-next-line
      return Promise.reject(response || { data: error.message });
    }
  );
  
// process.env.NODE_ENV === 'production' && 
// Sentry.configureScope(scope => {
//     const localInfo = window.localStorage.getItem('userId');
//     if (localInfo) {
//       scope.setUser({ 'id': localInfo });
//     }
// });

// 切换页面取消上个页面的所有请求
export const ajaxBoth = (key) => {
    let deleteIndexs = [];
    cancelList.forEach((item, index) => {
        if(key && item.key === key){
            deleteIndexs.push(index);
            item.cancel && item.cancel();
        }else{
            item.cancel && item.cancel();
        }
    });
    if(!key){
        cancelList = [];
    }else{
        deleteIndexs.forEach(item=>{
            cancelList.splice(item, 1);
        });
    }
};

export const ajaxAll = (method, url, params, data, headers) => {
    params.Region = utils.getLocalStorageRegion() &&  utils.getLocalStorageRegion() !== 'null' ? utils.getLocalStorageRegion() : 'cn-qingyangtest-1';
    return axios({
        method,
        url,
        params,
        data,
        cancelToken: new CancelToken(function (cancel) {
            cancelList.push({key: url, cancel});
        }),
        headers
    }).then(res=>{
        if (res.code && res.code !== 200) {
            if(process.env.NODE_ENV === 'production') {
                // Sentry.captureException (new Error(url + JSON.stringify(data.data) + 'parmas' +  JSON.stringify(''));
                // Sentry.captureException (JSON.stringify(res), {
                //     fingerprint: ['API', url],
                //     message: JSON.stringify(res),
                //     extra: method === 'get' ? params : data
                // });
            }
            return {
                status: false,
                data: res.data
            };
        }
        return {
            status: true,
            data: res.data
        };
    }).catch(error=>{
        if(error){
            if(process.env.NODE_ENV === 'production') {
                // Sentry.captureException (JSON.stringify(error.response && error.response.data), {
                //     fingerprint: ['API', url],
                //     message: JSON.stringify(error.response && error.response.data),
                //     extra:  method === 'get' ? params : data
                // }); 
                // error.response ? 
                // Sentry.captureException (new Error( url + JSON.stringify(error.response.data)))
                // : Sentry.captureException (new Error(url + '----error.response no data------' + JSON.stringify(error)));
            }
            return {
                data: error && error.data ? error.data : {},
                status: false
            };
            // return Promise.reject(error);
        }
    });
};


export const ajaxGet = (url, params) => {
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Ksc-Request-Id': uuidv4(),
        'X-Requested-With': 'XMLHttpRequest',
        'Front-Request-Id': uuidv4()
    };
    return ajaxAll('get',url, params, {}, headers);
};



export const ajaxPost = (url, params) => {
    let headers = {
        'Content-Type': 'application/json',
        'Front-Request-Id': uuidv4()
    };
    return ajaxAll('post',url, {}, params,headers);
};

