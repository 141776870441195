<template>

  <div class="body-all" id="app">
    <div v-if="isUnauthorized"
         class="none-authorized-tip">
      <p class="c-p-t-30">{{_$('EBS控制台目前无可用机房，如需进行产品体验')}}</p>
      <h3>{{_$('请联系客服(400-028-9900)')}}</h3>
    </div>
    <div :class="showTittle && inStanceDetail ? 'p-r-24 p-l-24 body-content' : 'body-content'"
         v-else>
      <div class="layout collapsed">
        <div id="nav"
            style="display:none"
             :class="collapse ? 'nav' : 'no-nav'">
          <Menu theme="light"
                size="small"
                dot
                :selectedKey.sync="selectNav">
            <template slot="header">
              <div class="nav-header">
                <Icon class="icon icon-kpc-ebs" />
                {{ _$('硬盘') }}
              </div>
            </template>
            <MenuItem v-for="item in navList"
                      :key="item.value"
                      @click="changeNav(item)">
            {{ item.text }}
            <Menu v-if="item.children">
              <MenuItem v-for="child in item.children"
                        :key="child.value"
                        @click="changeNav(child)">
              {{ child.text }}
              </MenuItem>
            </Menu>
            </MenuItem>
          </Menu>
          <div class="nav-link"
               @click="collapse = !collapse">
            <Icon class="icon-show icon-shrink-16"
                  v-show="collapse"></Icon>
            <Icon class="icon-hide icon-expand-16"
                  v-show="!collapse"></Icon>
          </div>
        </div>
        <div>
            <div class="app-header">
              <div class="inStanceDetail-header" v-if="!inStanceDetail">
                <Icon class="ion-chevron-left back" size="default" @click="utils.goOutConsole('kecList')"/>
                <span>{{tittleName === '硬盘' ? _$('本实例硬盘') : _$('本实例快照')}}</span>
              </div>
              <div v-if="showTittle && inStanceDetail" class="list-tittle" :style="tittleName === '回收站' || tittleName === '快照' ? 'height:60px' : 'height:36px'">
                <Regions
                    class="f-l"
                    :tittleName="tittleName"
                    @changeProjectValue="changeProjectValue"></Regions>
                <a @click="openHelp" v-if="tittleName !== '回收站'" class="f-r">
                  <Icon class="k-icon-paper" />
                  {{_$('相关文档')}}
                </a>
              </div>
            </div>
            <div style="background-color:#F8F8FA">
                <router-view :regionKey="regionKey" :commonConfig="commonConfig" :ebsPriceInfo="ebsPriceInfo" @getInStanceDetail="inStanceDetail=false" :newRegionsList="newRegionsList" :inStanceDetail="inStanceDetail" :projectValue="projectValue"/>
            </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import ebsApi from './api/ebsApi';
import commonApi from '@/api/commonApi';
import { Menu, MenuItem } from 'kpc/components/menu';
import Icon from 'kpc/components/icon';
import Regions from './components/regions.vue';
import navList from '../src/utils/navList';
import utils from './utils/utils';

export default ({
  name: 'app',
  components: {
    Menu,
    MenuItem,
    Icon,
    Regions

  },
  data () {
    return {
      isUnauthorized: false,//是否存在可用机房
      ebsPriceInfo: {},//价格体系列表
      regionsList: [],//机房列表
      regionKey: utils.getLocalStorageRegion(),//选中的region
      collapse: false,
      navList: navList,
      selectNav: 'volumeList',
      showTittle: false,
      tittleName: '',
      regionShow: false,
      newRegionsList: {},
      projectValue: '',
      // 是否是在主机详情页面，这里写反了，默认应该是 false
      inStanceDetail: true,
      commonConfig:{
        copySnapshotLimit: 10,
        copySnapshotRegion: {},
        setdeleteTimeSnapshotLimit: 10
      }
    };
  },
  methods: {
    changeNav (item) {
      if (item.type === 'outhref') {
        window.location.replace(item.href);
      } else if (item.type === 'myhref') {
        this.$router.push(item.href);
      }
    },
    getEBSPriceInfo () {
      ebsApi.getEBSPriceInfo({
        Flag: false
      }).then(res => {
        if (res.status) {
          let data = res.data.data;
          let ebsPriceInfo = data && data.length > 0 ? data.find(item => item.productGroupId === 101) : [];
        //   this.isUnauthorized = utils.isEmpty(ebsPriceInfo);
          this.ebsPriceInfo = ebsPriceInfo;
          this.getRegionlist();
          this.getQueryCommonConfig();
        }else{
            if(res.data !== 'sameRequest'){
                this.isUnauthorized = true;
                utils.showErrorTip(res);
            }
        }
      }).catch(err => {
            this.isUnauthorized = true;
            utils.showErrorTip(err);
      });
    },

    //根据价格体系返回获取机房列表
    getRegionlist () {
      let allList = utils.unRegions(this.ebsPriceInfo.productTypeList);
      let regionList = [];
      allList.forEach(element => {
        let {regionId, regionCode, regionName, regionEnName, areaName, areaEnName, areaCode} = { ...element };
        regionList.push({
            regionId, regionCode, regionName, regionEnName, areaName, areaEnName, areaCode
        });
      });
      if(self !== top && (this.$route.name === 'recycle' || this.$route.name === 'snapshotList' || this.$route.name === 'volumeList') && this.$route.query.type !== 'detail'){
        window.parent.postMessage({from: 'ebs', regionList, regionShow: true, region: utils.getLocalStorageRegion()},'*');
        window.localStorage.setItem('regions', JSON.stringify(regionList));
        this.isUnauthorized = false;
      }else{
        if (!_.isEmpty(regionList)) {
          this.regionsList = regionList;
          window.localStorage.setItem('regions', JSON.stringify(regionList));
          this.initSelect();
          this.isUnauthorized = false;
        } else {
            this.isUnauthorized = true;
        }
        // this.setRegionKey();
      }
        this.initRegionList();
    },

    //处理regoin数据
    initSelect() {
        let list = utils.initRegion(this.regionsList);
        this.newRegionsList.asia = list.asia;
        this.newRegionsList.Chinese = list.Chinese;
        this.newRegionsList.European = list.European;
    },

    // //设置默认region
    // setRegionKey () {
    //     if( !utils.getLocalStorageRegion() || utils.getLocalStorageRegion() === 'null' || (utils.getLocalStorageRegion() && !_.find(this.regionsList, item => {return item.regionCode === utils.getLocalStorageRegion();}))){
    //         let regionKey = this.regionsList[0].regionCode;
    //         utils.setLocalStorage(regionKey);
    //         window.localStorage.setItem('regionKey', regionKey);
    //         this.regionKey = regionKey;
    //     }else{
    //         window.localStorage.setItem('regionKey', utils.getLocalStorageRegion());
    //         this.regionKey =  utils.getLocalStorageRegion();
    //     }
    //     this.regionShow = true;
    //     this.initRegionList();
    // },

    // changeRegionKey(value){
    //     this.regionKey = value;
    //     utils.setLocalStorage(value);
    //     window.localStorage.setItem('regionKey', value);
    // },

    openHelp(){
        let document = '';
        if(this.tittleName === '硬盘'){
            document = '云硬盘使用指南';
        }
        if(this.tittleName === '快照'){
            document = '快照使用指南';
        }
        utils.gethelpDocument(this.filter.helpDocument(document));
    },

    showHead(val){
           // eslint-disable-next-line default-case
           switch (val.name) {
            case 'volumeList': this.tittleName = '硬盘'; this.showTittle = true; this.selectNav = 'volumeList'; break;
            case 'detail': this.tittleName = ''; this.showTittle = false; break;
            case 'recycle': this.tittleName = '回收站'; this.showTittle = true; this.selectNav = 'recycleEbs'; break;
            case 'snapshotList': this.tittleName = '快照'; this.showTittle = true; this.selectNav = 'snapshotList'; break;
            case 'create': this.tittleName = ''; this.showTittle = false; this.selectNav = ''; break;
            case 'unService': this.tittleName = ''; this.showTittle = false; this.selectNav = ''; break;
        }
    },
    changeProjectValue(value){
        window.localStorage.setItem('projectValue', value);
        this.projectValue = value;
    },
    async getQueryCommonConfig(){
        await commonApi.QueryCommonConfig().then(res => {
            if(res.status){
                this.commonConfig = Object.assign(this.commonConfig, JSON.parse(res.data.Result));
                let { helpDocumentList, regionMapList, availabilityZonenList, regionZonenList, instaneType} = this.commonConfig;
                this.filter.helpDocumentList = helpDocumentList;
                this.filter.regionMapList = regionMapList;
                this.filter.availabilityZonenList = availabilityZonenList;
                this.filter.regionZonenList = regionZonenList;
                this.filter.instaneType = instaneType;
            } else{
                utils.showErrorTip(res);
            }
        }).catch(err => {
            utils.showErrorTip(err);
        });
    },
    async initRegionList(){
        await commonApi.QuerySnapshotCopyRegionLimits().then(res => {
            if(res.status){
                let regionList =JSON.parse(res.data.Result);
                this.commonConfig = Object.assign(this.commonConfig, {copySnapshotRegion: regionList});
            }
        });
    }
  },
  created () {
    window.addEventListener('message', (event) => {
        // 判断消息来源
        if(event.data.from === 'kec'){
            if(event.data.region){
                window.localStorage.setItem('region', event.data.region);
                this.regionKey = event.data.region;
            }
        }
    }, false);
    utils.getLocalStorageRegion();
    this.getEBSPriceInfo();
  },
  mounted(){
    this.showHead(this.$route);
    let instanceId = this.$route.path.split('list/')[1];
    if(instanceId){
        this.$router.push({name: 'volumeList', query: { InstanceId: instanceId}});
    }
  },
  watch: {
    '$route': function (val) {
        if(val.query && val.query.type === 'detail'){
            this.inStanceDetail = false;
        }
        this.showHead(val);
        this.getRegionlist();
    }
  },
  computed: {
  }
});
</script>
<style lang="stylus">
@import './common/common.styl'
.p-r-24
  padding-right 24px
.p-l-24
  padding-left 24px
.app-header
  background #fff  
.body-all
  height 100%
  background-color #F8F8FA
  .body-content
    height 100%
    .layout
      height 100%
      .nav
        position fixed
        z-index 999
        float left
        width 180px
        height 100%
        padding-left 0
        margin-bottom 0
        margin-left 0
        background-color rgb(229, 229, 233)
        transition margin 0.3s ease-in-out
      .nav-link
        position absolute
        top 50%
        right 0
        z-index 5
        height 26px
        padding 0
        color #999
        border-top 3px solid transparent
        border-right 14px solid
        border-bottom 3px solid transparent
        border-right-color #fff
        .icon-show
          position relative
          top 1px
          right -13px
      .nav-header
        display flex
        i
          font-size 22px
      .no-nav
        margin-left -180px
        height 100%
        position fixed
        z-index 3
        float left
        width 180px
        padding-left 0
        margin-bottom 0
        transition margin 0.3s ease-in-out
        .k-menu
          width 0
      .nav-link
        position absolute
        top 50%
        z-index 5
        height 26px
        padding 0
        color #999
        border-right-color #fff
        right -26px
        border-top 3px solid transparent
        border-bottom 3px solid transparent
        border-left 14px solid #e5e5e9
        border-right-width 0
        .icon-hide
          right 13px
          position relative
          top 1px
      .collapse
        margin-left 180px
        height 100%
      .no-collapse
        margin-left 8px
        height 100%
  .none-authorized-tip
    position relative
    top 30%
    left 50%
    width 400px
    margin-left -200px
    font-size 14px
    text-align center
.list-tittle
    padding-top 16px
    height 36px
    margin-bottom 16px
    width calc(100% + 40px)
    position relative
    left -20px
    background #fff
    padding-left 24px
    padding-right 24px
  
    .k-icon-paper
      position relative
      top 2px

 .inStanceDetail-header
        width 100%
        height 50px
        padding 8px 20px 5px
        font-family PingFangSC-Medium
        line-height 25px
        font-size 14px
        font-weight 500
        color #333333
        background #FFFFFF
        .back
            display inline-block
            margin-right 8px
            vertical-align top
            &:hover
                color #0091ea
</style>
