import { Form } from 'kpc/components/form';
import utils from './utils';
import {_$} from 'kpc/components/utils';
Form.addMethod(
    'isEbsName',
    value => {
        return /^[0-9a-zA-Z\u4e00-\u9fa5_-]{2,50}$/.test(value);
    },
   _$('2-50个字符，字母，数字，-及_')
);
Form.addMethod(
    'snapshotDesc',
    value => {
        return /^[0-9a-zA-Z\u4e00-\u9fa5_-]{1,50}$/.test(value);
    },
   _$('1-50个字符，字母，数字，-及_')
);
Form.addMethod(
    'volumeDesc',
    value => {
        value = $.trim(value);
        return /^[0-9a-zA-Z\u4e00-\u9fa5_-]{1,100}$/.test(value);
    },
    _$('仅支持中文、大小写字母、数字、减号和下划线，1-100个字符')
);
//定时删除时间
Form.addMethod(
    'deleteTime',
    value => {
        return utils.timeOverHour(value);
    },
    _$('删除时间应在当前时间60分钟后')
);

//后缀值校验规则
Form.addMethod(
    'isSuffix',
    (value, item, params) => {
        let str = params + '_' + value;
        return str.length <= 50;
    },
    _$('名称+后缀长度不能超过50')
);

Form.addMethod(
    'lagoverZero',
    (value, item, params) => {
        return value > 0;
    },
    _$('后缀值必须大于0')
);

//定时删除时间
Form.addMethod(
    'checkboxBuy',
    value => {
        return value;
    },
    _$('请勾选确认')
);

//实例密码
Form.addMethod(
    'instancePassword',
    value => {
        return /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!$%()*+,\-./:;<=>?@\[\]^_`{|}~#]{8,32}$/.test(value);
    },
    _$('请填写正确的密码格式')
);