import { ajaxGet, ajaxPut,  ajaxDelete, ajaxPost} from './request';
const Service = 'ebs';
const Version = '2016-03-04';
const defaultUrlParam = `/i/ebs/api/?Service=${Service}&Version=${Version}`;

export default {
    // 获取价格体系
    getEBSPriceInfo(params) {
        return ajaxGet(`${defaultUrlParam}&Action=GetEBSPriceInfo`, params);
    },

    //获取云盘列表
    describeVolumes(params) {
        return ajaxPost(`${defaultUrlParam}&Action=DescribeVolumes`, params);
    },

    //修改云盘名称及描述
    ModifyVolume(params) {
        return ajaxPost(`${defaultUrlParam}&Action=ModifyVolume`, params);
    },

    //云盘项目制
    GetUserAllProjects(params) {
        return ajaxPost(`${defaultUrlParam}&Action=GetUserAllProjects`, params);
    },

    //更新云盘项目制
    UpdateVolumeProject(params) {
        return ajaxPost(`${defaultUrlParam}&Action=UpdateVolumeProject`, params);
    },

    //恢复云盘
    RecoveryVolume(params) {
        return ajaxPost(`${defaultUrlParam}&Action=RecoveryVolume`, params);
    },
   
    //删除云盘
    DeleteVolume(params) {
        return ajaxPost(`${defaultUrlParam}&Action=DeleteVolume`, params);
    },

    //续费商品计算价格
    calculateEBSRenewalPrice(params) { 
        return ajaxPost(`${defaultUrlParam}&Action=CalculateEBSRenewalPrice`, params);
    },

    //续费创建商品
    renewalProduct(params) { 
        return ajaxPost(`${defaultUrlParam}&Action=RenewalProduct`, params);
    },
    
    //查询用户试用余额
    queryFreeTrialWallet(){
        return ajaxGet(`${defaultUrlParam}&Action=QueryFreeTrialWallet`, {});
    },

    //试用转正计算价格
    CalculateEBSBillingPrice(params) { 
        return ajaxPost(`${defaultUrlParam}&Action=CalculateEBSBillingPrice`, params);
    },

    //试用转正创建商品
    BillingProduct(params) { 
        return ajaxPost(`${defaultUrlParam}&Action=BillingProduct`, params);
    },

    //根据云盘id获取标签
    ListTagsByVolumeIds(params){
        return ajaxPost(`${defaultUrlParam}&Action=ListTagsByVolumeIds`, params);
    },

    //获取全部标签键
    ListTagKeys(params){
        return ajaxPost(`${defaultUrlParam}&Action=ListTagKeys`, params);
    },

    //获取标签项
    ListTags(params){
        return ajaxPost(`${defaultUrlParam}&Action=ListTags`, params);
    },

    //根据标检键获取标签值
    ListTagValues(params){
        return ajaxPost(`${defaultUrlParam}&Action=ListTagValues`, params);
    },

    //根据云盘ID更新标签
    UpdateTagsByVolumeIds(params){
        return ajaxPost(`${defaultUrlParam}&Action=UpdateTagsByVolumeIds`, params);
    },

    //校验挂载主机的的可用性
    ValidateAttachInstance(params){
        return ajaxPost(`${defaultUrlParam}&Action=ValidateAttachInstance`, params);
    },

    //获取可挂载主机列表
    GetAvailableInstances(params){
        return ajaxPost(`${defaultUrlParam}&Action=GetAvailableInstances`, params);
    },

    //挂载卸载云盘
    AttachVolume(params){
        return ajaxPost(`${defaultUrlParam}&Action=AttachVolume`, params);
    },

    //查询密钥
    DescribeSksKeys(){
        return ajaxPost(`${defaultUrlParam}&Action=DescribeSksKeys`, {});
    },

    //查询主机镜像
    DescribeImages(params){
        return ajaxPost(`${defaultUrlParam}&Action=DescribeImages`, params);
    },
    
    //查询是否支持在线扩容
    ValidateResize(params){
        return ajaxPost(`${defaultUrlParam}&Action=ValidateResize`, params);
    },

    //扩容计算价格
    CalculateEBSUpdatePrice(params){
        return ajaxPost(`${defaultUrlParam}&Action=CalculateEBSUpdatePrice`, params);
    },

    //扩容创建商品
    UpdateProduct(params){
        return ajaxPost(`${defaultUrlParam}&Action=UpdateProduct`, params);
    },

    //查询云盘售罄信息
    GetVolumeSellOuts(params){
        return ajaxPost(`${defaultUrlParam}&Action=GetVolumeSellOuts`, params);
    },

    //校验用户配额
    CheckUserRight(params){
        return ajaxPost(`${defaultUrlParam}&Action=CheckUserRight`, params);
    },

    //计算云盘性能配额
    CalculateEbsQuotas(params){
        return ajaxGet(`${defaultUrlParam}&Action=CalculateEbsQuotas`, params);
    },
    
    DetachVolume(params){
        return ajaxPost(`${defaultUrlParam}&Action=DetachVolume`, params);
    },

    //云盘详情（支持批量）
    QueryVolumeDetail(params){
        return ajaxPost(`${defaultUrlParam}&Action=QueryVolumeDetail`, params);
    },

    //获取用户折扣信息
    QueryUserDiscounts(params) {
        return ajaxGet(`${defaultUrlParam}&Action=QueryUserDiscounts`, params);
    },

    //创建商品计算价格
    CalculateEBSPrice(params) {
        return ajaxPost(`${defaultUrlParam}&Action=CalculateEBSPrice`, params);
    },

    //创建商品
    CreateProduct(params) {
        return ajaxPost(`${defaultUrlParam}&Action=CreateProduct`, params);
    },

    //获取开盘数量配额
    GetBatchCreateNum(params) {
        return ajaxPost(`${defaultUrlParam}&Action=GetBatchCreateNum`, params);
    },

    //获取性能配额公式
    DescribeEbsQuotas(params) {
        return ajaxPost(`${defaultUrlParam}&Action=DescribeEbsQuotas`, params);
    },
    
    //转换计费方式
    ModifyDiskChargeType(params){
        return ajaxPost(`${defaultUrlParam}&Action=ModifyDiskChargeType`, params);
    },

    //获取是否存在待生效的转换计费方式任务
    ListBillTypeModifyTask(params){
        return ajaxPost(`${defaultUrlParam}&Action=ListBillTypeModifyTask`, params);
    },

    //转换计费方式计算价格
    CalculateEBSTransPrice(params){
        return ajaxPost(`${defaultUrlParam}&Action=CalculateEBSTransPrice`, params);
    },

    //批量查询云盘是否有待生效的计费方式
    BatchQueryBilltypeModifyTask(params){
        return ajaxPost(`${defaultUrlParam}&Action=BatchQueryBilltypeModifyTask`, params);
    },


    //获取实例可挂载的云盘类型
    GetSupportVolumeType(params){
        return ajaxGet(`${defaultUrlParam}&Action=GetSupportVolumeType`, params);
    },

    ModifyVolumeType(params){
        return ajaxGet(`${defaultUrlParam}&Action=ModifyVolumeType`, params);
    }

};
