import {_$} from 'kpc';

let navList = [
    {
        value: 'overview',
        text: _$('概览'),
        href: '//kec.console.ksyun.com/v2/#/overview',
        type:'outhref'
    },
    {
        value: 'kec',
        text: _$('实例'),
        href: '//kec.console.ksyun.com/v2/#/kec',
        type:'outhref'
    },
    {
        value: 'launchTemplate',
        text: _$('实例启动模板'),
        href: '//kec.console.ksyun.com/v2/#/kec/launchTemplate',
        type:'outhref'
    },
    {
        value: 'volume',
        text: _$('硬盘'),
        href: 'javascript:;',
        children: [
            {
                text: _$('本地硬盘'),
                value: 'volumeLocal',
                href: '//kec.console.ksyun.com/#/disk/local',
                type:'outhref'
            },
            {
                value: 'volumeList',
                text: _$('云硬盘'),
                href: '/volume/list',
                type:'myhref'
            }
        ]
    },
    {
        value: 'snapshot',
        text: _$('快照'),
        href: 'javascript:;',
        children: [
            {
                value: 'snapshotList',
                text: _$('快照列表'),
                // href: "/#/snapshot/list",
                href: '/snapshot/list',
                type:'myhref'
            },
            {
                value: 'snapshotChain',
                text: _$('快照链'),
                type:'outhref',
                href: '//kec.console.ksyun.com/v2/#/kec/snapshot/chain'
            },
            {
                value: 'setSnapshot',
                text: _$('自动快照策略'),
                type:'outhref',
                href: '//kec.console.ksyun.com/v2/#/kec/snapshot/snapstrategy'
            },
            {
                value: 'snapshotVolume',
                text: _$('快照容量'),
                type:'outhref',
                href: '//kec.console.ksyun.com/v2/#/kec/snapshot/capacity'
            }
        ]
    },
    {
        value: 'image',
        text: _$('镜像'),
        href: '//kec.console.ksyun.com/v2/#/kec/image',
        type:'outhref'
    },
    {
        value: 'vpc',
        text: _$('安全组（防火墙）'),
        href: '//kec.console.ksyun.com/#/group/list',
        type:'outhref'
    },
    {
        value: 'rongzai',
        text: _$('容灾组'),
        href: '//kec.console.ksyun.com/v2/#/kec/avoid',
        type:'outhref'
    },
    {
        value: 'recycle',
        text: _$('回收站'),
        href: 'javascript:;',
        children: [
            {
                value: 'recycleKec',
                text: _$('云服务器回收站'),
                href: '//kec.console.ksyun.com/v2/#/kec/recycleBin/cloudServer',
                type:'outhref'
            },
            {
                value: 'recycleEbs',
                text: _$('云硬盘回收站'),
                href: '/volume/recycle',
                type:'myhref'
            }
        ]
    },
    {
        value: 'sshKey',
        text: _$('SSH密钥'),
        href: '//kec.console.ksyun.com/v2/#/kec/ssh'
    }
];
export default  navList;