import { ajaxGet, ajaxPut,  ajaxDelete, ajaxPost} from './request';
const Service = 'ebs';
const Version = '2016-03-04';
const defaultUrlParam = `/i/ebs/api/?Service=${Service}&Version=${Version}`;

export default {
    // 快照服务是否开通
    ValidateSnapshotService(parmas) {
        return ajaxGet(`${defaultUrlParam}&Action=ValidateSnapshotService`, parmas);
    },

    //开通快照服务
    OpenSnapshotService(parmas) {
        return ajaxPost(`${defaultUrlParam}&Action=OpenSnapshotService`, parmas);
    },

    //快照价格体系
    GetSnapshotPriceInfo(parmas){
        return ajaxPost(`${defaultUrlParam}&Action=GetSnapshotPriceInfo`, parmas);
    },

    //查询快照信息
    DescribeSnapshots(parmas){
        return ajaxPost(`${defaultUrlParam}&Action=DescribeSnapshots`, parmas);
    },

    //修改快照信息（名称）
    ModifySnapshot(parmas){
        return ajaxPost(`${defaultUrlParam}&Action=ModifySnapshot`, parmas);
    },

    //删除快照
    DeleteSnapshot(params) {
        return ajaxPost(`${defaultUrlParam}&Action=DeleteSnapshot`, params);
    },

    //回滚快照
    RollbackSnapshot(params) {
        return ajaxPost(`${defaultUrlParam}&Action=RollbackSnapshot`, params);
    },

    //检测是否在自动快照白名单
    ValidateAutoSnapshot(parmas) {
        return ajaxGet(`${defaultUrlParam}&Action=ValidateAutoSnapshot`, parmas);
    },

    //查询自动快照策略
    DescribeAutoSnapshotPolicy(parmas){
        return ajaxGet(`${defaultUrlParam}&Action=DescribeAutoSnapshotPolicy`, parmas);
    },

    //判断策略相关的硬盘数量
    CalculatePolicyInVolumeNum(params) {
        return ajaxPost(`${defaultUrlParam}&Action=CalculatePolicyInVolumeNum`, params);
    },

    //绑定/更新自动快照策略
    ApplyAutoSnapshotPolicy(params) {
        return ajaxPost(`${defaultUrlParam}&Action=ApplyAutoSnapshotPolicy`, params);
    },

    //解绑自动快照策略
    CancelAutoSnapshotPolicy(params) {
        return ajaxPost(`${defaultUrlParam}&Action=CancelAutoSnapshotPolicy`, params);
    },

    //用户快照数量
    GetSnapshotNumInfo(params) {
        return ajaxPost(`${defaultUrlParam}&Action=GetSnapshotNumInfo`, params);
    },

    //创建快照
    CreateSnapshot(params) {
        return ajaxPost(`${defaultUrlParam}&Action=CreateSnapshot`, params);
    },

    //获取本地盘快照列表
    DescribeLocalVolumeSnapshots(params){
        return ajaxPost(`${defaultUrlParam}&Action=DescribeLocalVolumeSnapshots`, params);
    },

    //校验镜像配额
    GetImageNumQuota(){
        return ajaxGet(`${defaultUrlParam}&Action=GetImageNumQuota`, {});
    },

    //创建整机镜像
    CreateAssembledImage(params){
        return ajaxPost(`${defaultUrlParam}&Action=CreateAssembledImage`, params);
    },

    //设置定时删除时间
    ModifySnapshotScheduledTime(parmas){
        return ajaxPost(`${defaultUrlParam}&Action=ModifySnapshotScheduledTime`, parmas);
    },
    //跨region复制
    CopySnapshot(parmas){
        return ajaxPost(`${defaultUrlParam}&Action=CopySnapshot`, parmas);
    },
    //获取快照镜像
    DescribeSnapshotRelatedImage(parmas){
        return ajaxPost(`${defaultUrlParam}&Action=DescribeSnapshotRelatedImage`, parmas);
    },
    //判断用户是否有本地快照转换的权限
    ValidateSnapshotTransform(){
        return ajaxGet(`${defaultUrlParam}&Action=ValidateSnapshotTransform`, {});
    },
    //本地快照转换
    ModifySnapshotType(parmas){
        return ajaxPost(`${defaultUrlParam}&Action=ModifySnapshotType`, parmas);
    },
    //查询是否启用快照复制功能
    DescribeSnapshotCopyEnableQuota(){
        return ajaxGet(`${defaultUrlParam}&Action=DescribeSnapshotCopyEnableQuota`, {});
    }
};
