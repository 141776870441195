import Vue from 'vue';
import Router from 'vue-router';
const originalPush = Router.prototype.push;

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
const volumeList = () => import( '../views/volume/list/list.vue'); //云盘列表
const create = () => import( '../views/volume/create/create.vue'); //新建云盘
const detail = () => import('../views/volume/detail/detail.vue');//云盘详情
const unService = () => import('../views/snapshot/unService.vue');//未开通快照服务
const snapshotList = () => import('../views/snapshot/snapshotList/snapshotList.vue');//快照列表
const recycle = () => import('../views/volume/recycle/recycle.vue');//云盘回收站列表

Vue.use(Router);
export default new Router({
    routes:[
        {
            path:'/',
            // name: 'default',
            redirect:'/volume/list'
            // component: volumeList

        },
        {
            path: '/volume/list',
            name: 'volumeList',
            component: volumeList
        },
        {
            path: '/volume/create',
            name: 'create',
            component: create
        },
        {
            path: '/volume/detail',
            name: 'detail',
            component: detail 
        },
        {
            path: '/snapshot/unService',
            name: 'unService',
            component: unService 
        },
        {
            path: '/snapshot/list',
            name: 'snapshotList',
            component: snapshotList 
        },
        {
            path: '/volume/recycle',
            name: 'recycle',
            component: recycle 
        } 
    ]
});
  
  