<template>
  <div :tittleName="tittleName">
    <div style="display:inline-block;font-size:14px;margin-right:16px;color:#262626;line-height:20px;font-weght:500;font-family: PingFangSC-Medium;">
      {{ _$(tittleName) }}
    </div>
    <!-- <div class="c-regions">
      <Select :value="_$(filter.regionMap(regionKey))"
              class="custom-select"
              allowUnmatch
              ref="select">
        <template slot="value" slot-scope="value">
            <div>
                <img src="@/assets/images/region.svg" style="margin-top:-3px"/>
                {{ value }}
            </div>
        </template>
        <template slot="menu">
          <div class="region-select">
            <span class="tittle-border"></span>
            <Card type="border"
                  :title="_$('全部地域')">
              <div class="region-tittle"  v-if=" newRegionsList.Chinese && newRegionsList.Chinese.length>0 " >
                    <p>{{_$('国内')}}</p>
                    <Button 
                        :class="item.regionCode === regionKey ? 'region-chselect'  : 'region-chbutton'"
                        @click="changeRegionKey(item.regionCode)"
                        v-for="(item,index) in newRegionsList.Chinese" :key="index" >
                        {{ _$(item.regionName) }}
                    </Button>
              </div>
              <div class="region-tittle"  v-if=" newRegionsList.asia && newRegionsList.asia.length>0 " >
                 <p>{{_$('亚太')}}</p>
                    <Button 
                        :class="item.regionCode === regionKey ? 'region-chselect'  : 'region-chbutton'"
                        @click="changeRegionKey(item.regionCode)"
                        v-for="(item,index) in newRegionsList.asia" :key="index" >
                        {{  _$(item.regionName) }}
                    </Button>
              </div>
              <div class="region-tittle"  v-if=" newRegionsList.European && newRegionsList.European.length>0 " >
                 <p>{{_$('欧洲')}}</p>
                    <Button 
                        :class="item.regionCode === regionKey ? 'region-chselect'  : 'region-chbutton'"
                        @click="changeRegionKey(item.regionCode)"
                        v-for="(item,index) in newRegionsList.European" :key="index" >
                        {{  _$(item.regionName) }}
                    </Button>
              </div>
            </Card>
          </div>
        </template>
      </Select>
    </div> -->
    <div v-if="tittleName === '回收站'" class="d-inline-block m-l-8 ver-align-t">
        <Select v-model="projectValue"
                allowUnmatch
                class="project-select"
                width="220">
            <Option v-for="(item,index) in projectList"
                    :value="item.ProjectId + ''"
                    :label="item.ProjectName"
                    :key="index">
                <div>
                    <p class="m-all-0">{{ item.ProjectName ? _$(item.ProjectName) : ''}}</p>
                    <p class="status-invalid m-all-0">{{ item.ProjectDesc ? _$(item.ProjectDesc) : '' }}</p>
                </div>
            </Option>
        </Select>
    </div>

  </div>
</template>
<script>
import Button from 'kpc/components/button';
import { Select, Option } from 'kpc/components/select';
import Card from 'kpc/components/card';
import ebsApi from '../api/ebsApi';
export default {
    name: 'Regions',
    components: {
        Button,
        Select,
        Card, 
        Option
    },
    data () {
        return {
            selectRegionKey: '',
            projectList: [],//项目制列表
            projectValue: 'all'//项目制

        };
    },
    props: {
        tittleName: { type: String }
    },
    methods: {
        changeRegionKey(value){
            this.projectValue = 'all';
            this.$emit('changeRegionKey',value);
            this.$refs.select.hide();
        },
        //获取项目制信息
        getUserAllProjects(){
            ebsApi.GetUserAllProjects({
                projectStatus: 0
            }).then(res => {
                if(res.status){
                    let list = res.data.ListProjectResult.ProjectList;
                    list = _.sortBy(list, 'ProjectId');
                    list.unshift({
                        ProjectId:'all',
                        ProjectName:_$('全部项目')
                    });
                    this.projectList = list;
                }else{
                    utils.showErrorTip(res);
                    this.projectValue = '';
                }
            })
            .catch( err => {
                utils.showErrorTip(err);
                this.projectValue = '';
            });
        }
    },
    mounted () {
        this.selectRegionKey = this.regionKey;
        if(this.tittleName === '回收站'){
            this.getUserAllProjects();
        }
    },
    watch:{
        'projectValue': function(val){
            this.$emit('changeProjectValue',this.projectValue);
        },
        'tittleName': function(val){
            if(this.tittleName === '回收站'){
                this.getUserAllProjects();
            }
        }
    }


};
</script>
<style lang="stylus">
.region-select
  width 580px
  max-height 100%
  .tittle-border
    position absolute
    top 48px
    width 546px
    height 1px
    margin-left 16px
    background-color #e5e5e5
  .k-header
    position relative
    border-bottom 0 !important
    .k-title
      font-size 14px
  .k-body
    padding 16px 8px 8px 8px
  .region-tittle
    margin-left 0
    color #d5d5d9
    p
      margin-left 8px
   .region-chbutton
        width 160px
        padding-right 8px
        padding-left 8px
        margin-right 8px
        margin-bottom 8px
        font-size 12px
        text-align left
        text-overflow ellipsis
        word-break revert
        white-space inherit
        border 0
        height auto 

        span
            line-height 24px

    .region-chbutton:hover
        color black !important
        background-color #e5e5e9 !important
    .region-chselect
        width 160px
        height auto 
        padding-right 8px
        padding-left 8px
        margin-right 8px
        margin-bottom 8px
        font-size 12px
        color #0091ea
        text-align left
        text-overflow ellipsis
        word-break revert
        white-space inherit
        background-color #e5f7ff
        border 0

        span
            line-height 24px
    .region-chselect:hover
        color #0091ea !important
        background-color #e5f7ff !important
.c-regions
  position relative
  display inline-block
  vertical-align top
  .k-select
      width 200px
  .custom-select
    max-height 100%
    .k-main
        padding-left 16px
        img
            margin-right 8px
            vertical-align middle
  .area-icon
    position absolute
    left 8px
    top 7px
    z-index 100
    color #0091ea
.custom-select
    max-height max-content
.project-select
  .k-item
    height auto !important
    p
      margin 0
</style>
