<template>
  <div id="bq-loading"
       @click.stop
       v-show="show">
    <img src="@/assets/images/page-loading.gif"
         alt=""
         class="loading-img pc-loading-img">
  </div>
</template>


<script>
export default {
  name: '',
  data () {
    return {
      show: false
    };
  },
  props: {},
  components: {},
  mounted () { },
  methods: {}
};
</script>

<style lang="stylus">
#bq-loading
  width 100vw
  height 100vh
  background-color transparent
  color #000
  position fixed
  left 0
  top 0
  right 0
  bottom 0
  display flex
  justify-content center
  align-items center
  z-index 99999
</style>