import { _$ } from 'kpc/components/utils';
import { validate } from 'uuid';
import utils from '../utils/utils';
import store from '../store/store';
import { ESSDVolumeProductType } from '@/utils/constants';

let filters = {
    regionMapList: {
        'cn-beijing-6': '华北1（北京）',
        'cn-shanghai-2': '华东1（上海）',
        'cn-shanghai-3': '华东2（上海）',
        'ap-singapore-1': '新加坡',
        'eu-east-1': '俄罗斯（莫斯科）',
        'cn-taipei-1': '台北',
        'cn-guangzhou-1': '华南1（广州）',
        'cn-qingdao-1': '自用（青岛）',
        'cn-shanghai-fin': '华东金融1（上海）',
        'cn-beijing-fin': '	华北金融1（北京）',
        'cn-north-1-gov': '华北政务1（北京）',
        'cn-qingdaodev-1': '自用（青岛2）',
        'cn-northwest-1': '西北1区（庆阳）',
        'cn-northwest-2': '西北2区（自用）',
        'cn-hongkong-2': '香港',
        'cn-qingyangtest-1': '庆阳1区（测试）',
        'cn-shanghai-3a': '可用区A',
        'cn-shanghai-3b': '可用区B',
        'cn-shanghai-3d': '可用区D',
        'cn-beijing-6a': '可用区A',
        'cn-beijing-6b': '可用区B',
        'cn-beijing-6c': '可用区C',
        'cn-beijing-6d': '可用区D',
        'cn-beijing-6e': '可用区E',
        'cn-shanghai-2a': '可用区A',
        'cn-shanghai-2b': '可用区B',
        'cn-shanghai-2d': '可用区D',
        'ap-singapore-1a': '可用区A',
        'ap-singapore-1b': '可用区B',
        'ap-singapore-1c': '可用区C',
        'eu-east-1a': '可用区A',
        'eu-east-1b': '可用区B',
        'cn-taipei-1a': '可用区A',
        'cn-taipei-1b': '可用区B',
        'cn-guangzhou-1a': '可用区A',
        'cn-guangzhou-1b': '可用区B',
        'cn-qingdao-1a': '可用区A',
        'cn-qingdao-1b': '可用区B',
        'cn-shanghai-fin-a': '可用区A',
        'cn-shanghai-fin-b': '可用区B',
        'cn-beijing-fin-a': '可用区A',
        'cn-beijing-fin-b': '可用区B',
        'cn-north-1-gov-a': '可用区A',
        'cn-north-1-gov-b': '可用区B',
        'cn-qingdaodev-1a': '可用区A',
        'cn-northwest-1a': '可用区A',
        'cn-hongkong-2a': '可用区A',
        'cn-northwest-2a': '可用区A',
        'cn-qingyangtest-1a': '可用区A',
        'cn-qingyangtest-1b': '可用区B'
    },
    regionMap: function (value) {
        return utils.keyMap(filters.regionMapList)(value);
    },
    availabilityZonenList: {
        'cn-shanghai-3a': '华东2（上海）可用区A',
        'cn-shanghai-3b': '华东2（上海）可用区B',
        'cn-shanghai-3d': '华东2（上海）可用区D',
        'cn-beijing-6a': '华北1（北京）可用区A',
        'cn-beijing-6b': '华北1（北京）可用区B',
        'cn-beijing-6c': '华北1（北京）可用区C',
        'cn-beijing-6d': '华北1（北京）可用区D',
        'cn-beijing-6e': '华北1（北京）可用区E',
        'cn-shanghai-2a': '华东1（上海）可用区A',
        'cn-shanghai-2b': '华东1（上海）可用区B',
        'cn-shanghai-2d': '华东1（上海）可用区D',
        'ap-singapore-1a': '新加坡可用区A',
        'ap-singapore-1b': '新加坡可用区B',
        'ap-singapore-1c': '新加坡可用区C',
        'eu-east-1a': '俄罗斯（莫斯科）可用区A',
        'eu-east-1b': '俄罗斯（莫斯科）可用区B',
        'cn-taipei-1a': '台北可用区A',
        'cn-taipei-1b': '台北可用区B',
        'cn-guangzhou-1a': '华南1（广州）可用区A',
        'cn-guangzhou-1b': '华南1（广州）可用区B',
        'cn-qingdao-1a': '自用（青岛）可用区A',
        'cn-qingdao-1b': '自用（青岛）可用区B',
        'cn-shanghai-fin-a': '华东金融1（上海）可用区A',
        'cn-shanghai-fin-b': '华东金融1（上海）可用区B',
        'cn-beijing-fin-a': '华北金融1（北京）可用区A',
        'cn-beijing-fin-b': '华北金融1（北京）可用区B',
        'cn-north-1-gov-a': '华北政务1（北京）可用区A',
        'cn-north-1-gov-b': '华北政务1（北京）可用区B',
        'cn-qingdaodev-1a': '自用（青岛2）可用区A',
        'cn-northwest-1a': '西北1区（庆阳）可用区A',
        'cn-northwest-2a': '西北2区（自用）可用区A',
        'cn-hongkong-2a': '香港可用区A',
        'cn-qingyangtest-1a': '庆阳1区（测试）可用区A',
        'cn-qingyangtest-1b': '庆阳1区（测试）可用区B'
    },
    availabilityZonenMap: function (value) {
        return utils.keyMap(filters.availabilityZonenList)(value);
    },
    azMapList: function (value) {
        return utils.keyMap(filters.regionMapList)(value);
    },
    regionZonenList:{
        'cn-shanghai-3a': '华东2（上海）',
        'cn-shanghai-3b': '华东2（上海）',
        'cn-shanghai-3d': '华东2（上海）',
        'cn-beijing-6a': '华北1（北京）',
        'cn-beijing-6b': '华北1（北京）',
        'cn-beijing-6c': '华北1（北京）',
        'cn-beijing-6d': '华北1（北京）',
        'cn-beijing-6e': '华北1（北京）',
        'cn-shanghai-2a': '华东1（上海）',
        'cn-shanghai-2b': '华东1（上海）',
        'cn-shanghai-2d': '华东1（上海）',
        'ap-singapore-1a': '新加坡',
        'ap-singapore-1b': '新加坡',
        'ap-singapore-1c': '新加坡',
        'eu-east-1a': '俄罗斯（莫斯科）',
        'eu-east-1b': '俄罗斯（莫斯科）',
        'cn-taipei-1a': '台北',
        'cn-taipei-1b': '台北',
        'cn-guangzhou-1a': '华南1（广州）',
        'cn-guangzhou-1b': '华南1（广州）',
        'cn-qingdao-1a': '自用（青岛）',
        'cn-qingdao-1b': '自用（青岛）',
        'cn-shanghai-fin-a': '华东金融1（上海）',
        'cn-shanghai-fin-b': '华东金融1（上海）',
        'cn-beijing-fin-a': '华北金融1（北京）',
        'cn-beijing-fin-b': '华北金融1（北京）',
        'cn-north-1-gov-a': '华北政务1（北京）',
        'cn-north-1-gov-b': '华北政务1（北京）',
        'cn-qingdaodev-1a': '自用（青岛2）',
        'cn-northwest-1a': '西北1区（庆阳）',
        'cn-northwest-2a': '西北2区（自用）',
        'cn-qingyangtest-1a': '庆阳1区（测试）',
        'cn-qingyangtest-1b': '庆阳1区（测试）',
        'cn-hongkong-2a': '香港'
    },
    regionZonenMap: function (value) {
        return utils.keyMap(filters.regionZonenList)(value);
    },
    snapshotStatus: function (value) {
        return utils.keyMap({
            creating: '创建中',
            available: '可用',
            'backing-up': '备份中',
            deleting: '删除中',
            deleted: '已删除',
            error: '错误',
            'error_deleting': '删除失败',
            rollbacking: '回滚中',
            converting: '快照类型转换中'
        })(value);
    },

    volumeStatus: function (value) {
        return utils.keyMap({
            'all': _$('全部'),
            'in-use': '使用中',
            creating: '创建中',
            rollbacking: '回滚中',
            available: '待挂载',
            attaching: '挂载中',
            'error_attaching': '挂载失败',
            detaching: '卸载中',
            'error_detaching': '卸载失败',
            error: '错误',
            'error_deleting': '删除失败',
            deleting: '删除中',
            deleted: '已删除',
            extending: '扩容中',
            'error_extending': '扩容失败',
            recycling: '回收中',
            downloading: '创建中',
            'type_changing': '类型变更中'
        })(value);
    },

    //云盘类型
    ebsTypeMap: function (value, isCreateOptimised) {
        if(isCreateOptimised){
            return ' 云硬盘3.0（SSD批量开机优化型）';
        }
        return utils.keyMap({
            SATA: '云硬盘2.0（SATA）',
            SSD: '云硬盘2.0（SSD）',
            'SSD2.0': '云硬盘2.0（SSD）',
            'SSD3.0': '云硬盘3.0（SSD）',
            'SATA2.0': '云硬盘2.0（SATA）',
            'SATA3.0': '云硬盘3.0（SATA）',
            EHDD: '高效云盘',
            ESSD_PL3: '极速云盘(PL3)',
            ESSD_PL1: '极速云盘(PL1)',
            ESSD_PL2: '极速云盘(PL2)',
            ESSD_PL0: '极速云盘(PL0)',
            ESSD: '极速云盘ESSD',
            ESSD_SYSTEM_PL0: '极速云盘(PL0)',
            ESSD_SYSTEM_PL1: '极速云盘(PL1)',
            ESSD_SYSTEM_PL2: '极速云盘(PL2)'
        })(value);
    },

    //essd云盘类型
    ESSDMap: function (value) {
        return utils.keyMap({
            ESSD_PL3: 'PL3(单盘性能上限IOPS:100万 吞吐量:4000MB/s)',
            ESSD_PL1: 'PL1(单盘IOPS性能上限5万 吞吐量:350MB/s)',
            ESSD_PL2: 'PL2(单盘IOPS性能上限10万 吞吐量:750MB/s)',
            ESSD_PL0: 'PL0(单盘IOPS性能上限1万 吞吐量:180MB/s)',
            ESSD: '极速型云盘ESSD'
        })(value);
    },

    ESSDSizeTypeMap: function (value) {
        return utils.keyMap({
            ESSD_PL0: 'PL0级别容量范围：',
            ESSD_PL1: 'PL1级别容量范围：',
            ESSD_PL2: 'PL2级别容量范围：',
            ESSD_PL3: 'PL3级别容量范围：'
        })(value);
    },

    volumeType: function (value) {
        return utils.keyMap({
            SATA: 'SATA2.0',
            SSD: 'SSD2.0',
            'SSD2.0': 'SSD2.0',
            'SSD3.0': 'SSD3.0',
            'SATA2.0': 'SATA2.0',
            'SATA3.0': 'SATA3.0',
            EHDD: 'EHDD'
        })(value);
    },
    volumeProductType: function (value) {
        return utils.keyMap({
            SATA: '4',
            SSD: '3',
            'SATA2.0': '4',
            'SSD2.0': '3',
            'SATA3.0': '133',
            'SSD3.0': '132',
            EHDD: '197',
            ESSD_PL0: '566',
            ESSD_PL3: '501',
            ESSD_PL2: '564',
            ESSD_PL1: '565',
            'ESSD_SYSTEM_PL0': '701',
            'ESSD_SYSTEM_PL1': '702',
            'ESSD_SYSTEM_PL2': '703'
        })(value);
    },
    ESSD_SYSTEM_MAP: function (value) {
        return utils.keyMap({
            'ESSD_SYSTEM_PL0': 'ESSD_PL0',
            'ESSD_SYSTEM_PL1': 'ESSD_PL1',
            'ESSD_SYSTEM_PL2': 'ESSD_PL2'
        })(value);
    },
    ExpireStatusMap: function (value) {
        return utils.keyMap({
            'expiring-soon': '即将到期',
            expired: '已到期'
        })(value);
    },
    billTypeList: function (value) {
        return utils.keyMap({
            1: '包年包月',
            5: '按量付费（按日月结）',
            84: '按量付费（按小时月结）',
            87: '按量付费',
            52: '按日配置付费(试用)',
            810: '竞价型实例',
            Monthly: '包年包月',
            Daily: '按量付费（按日月结）',
            PostPaidByHour: '按量付费（按小时月结）',
            HourlyInstantSettlement: '按量付费',
            Spot: '竞价型实例'
        })(value);
    },
    billTypeIdList: function (value) {
        return utils.keyMap({
            1: 'Monthly',
            5: 'Daily',
            84: 'PostPaidByHour）',
            87: 'HourlyInstantSettlement',
            Monthly: 1,
            Daily: 5,
            PostPaidByHour: 84,
            HourlyInstantSettlement: 87
        })(value);
    },
    recyleBillTypeList: function (value) {
        return utils.keyMap({
            1: '包年包月',
            5: '按量付费（按日月结）',
            84: '按量付费（按小时月结）',
            87: '按量付费',
            52: '按日配置付费(试用)',
            810: '竞价型实例',
            Monthly: '包年包月',
            Daily: '(按日月结)',
            PostPaidByHour: '(按小时月结)',
            HourlyInstantSettlement: '按量付费',
            Spot: '竞价型实例'
        })(value);
    },
    timeUnit: function (value) {
        return utils.keyMap({
            Monthly: ' ',
            Daily: _$('/ 天'),
            PostPaidByHour: _$('/ 时'),
            HourlyInstantSettlement: _$('/ 时'),
            Spot: ' ',
            1: ' ',
            5: _$('/ 天'),
            84: _$('/ 时'),
            52: _$('/ 天'),
            87: _$('/ 时')
        })(value);
    },
    //盘类型
    VolumeCategory: function (value) {
        return utils.keyMap({
            data: '数据盘',
            system: '系统盘'
        })(value);
    },
    //快照类型
    SnapshotType: function (value) {
        return utils.keyMap({
            CommonSnapShot: '普通快照',
            CommonSnapshot: '普通快照',
            LocalSnapShot: '极速可用快照',
            LocalSnapshot: '极速可用快照'
        })(value);
    },

    data: function (value) {
        return utils.keyMap({
            1: _$('周一'),
            2: _$('周二'),
            3: _$('周三'),
            4: _$('周四'),
            5: _$('周五'),
            6: _$('周六'),
            7: _$('周日')
        })(value);
    },
    currencyUnit: function (value) {
        return utils.keyMap({
            CNY: '￥',
            USD: '$',
            JPY: '¥',
            KRW: '₩',
            HKD: '$',
            GBP: '$',
            EUR: '€'
        })(value);
    },
    //控制台悬浮文档
    helpDocument: function (value) {
        return utils.keyMap(filters.helpDocumentList)(value);
    },
    helpDocumentList: {
        '云硬盘使用指南': '//docs.ksyun.com/documents/1046',
        '快照使用指南': '//docs.ksyun.com/documents/2684',
        'createSnapshot': '//docs.ksyun.com/documents/2684',
        'recycleBin': '//docs.ksyun.com/documents/1055',
        'upgradeCreateSnapshot': '//docs.ksyun.com/documents/5266',
        'upgradeLinux': '//docs.ksyun.com/documents/39926',
        'upgradeWindow': '//docs.ksyun.com/documents/39927',
        'upgradeCareful': '//docs.ksyun.com/documents/39865',
        'snapshotPrice': '//docs.ksyun.com/documents/37052',
        'snapshotAgreement': '//docs.ksyun.com/documents/2737',
        'snapshotBilling': '//sw.ksyun.com/pro/calc/#/oth/37393/doc',
        'essdHelp': '//docs.ksyun.com/documents/2676',
        'billType': '//docs.ksyun.com/documents/2675'
    },
    volumeListKey:function(value){
        return utils.keyMap({
            VolumeId: '云硬盘ID',
            VolumeName: '云盘名称',
            InstanceId: '实例ID',
            AutoSnapshotPolicyId: '策略ID',
            Tag: '云硬盘标签'

        })(value);
    },
    currencyMap:function(value){
        return utils.keyMap({
            CNY: '￥',
            USD: '$',
            JPY: '¥',
            KRW: '₩',
            HKD: '$',
            GBP: '$',
            EUR: '€'
        })(value);
    },
    instaneType: {
        all: 'X6\X6E标准型\X7',
        ESSD_PL0: 'X6、X6E标准型、X7',
        ESSD_PL1: 'X6、X6E标准型、X7',
        ESSD_PL2: 'X6、X6E标准型、X7'
    },
    listAction:function(type,item, overdue){
        let show = false;
        let over = overdue && overdue.overdue;
        if(type === 'renewal'){
            if(item.OriginalCategory && item.OriginalCategory === 'system'){
                show = false;
            }else{
                show = item.VolumeCategory === 'data' && item.ChargeType === 'Monthly' && (item.VolumeStatus === 'in-use' || item.VolumeStatus === 'available');
            }
        }
        if(type === 'postpone'){
            if(item.OriginalCategory && item.OriginalCategory === 'system'){
                show = false;
            }else{
                show = item.VolumeCategory === 'data' && item.InstanceTradeType === 2 && (item.VolumeStatus === 'in-use' || item.VolumeStatus === 'available');
            }
        }
        if(type === 'attachVolume'){
            // show = item.VolumeCategory === 'data' &&  item.VolumeStatus === 'available' && !over;
            show = item.VolumeStatus === 'available' && !over;
        }
        if(type === 'unAttachVolume'){
            show = item.VolumeCategory === 'system' || (item.VolumeCategory === 'data' && item.VolumeStatus === 'in-use' && item.InstanceType !== 'kci') ;
            // if(item.VolumeCategory === 'system' &&item.Attachment && item.Attachment.length === 1 && item.Attachment[0].InstanceState === 'stopped')
        }
        if(type === 'updateProduct'){
            if(item.OriginalCategory && item.OriginalCategory === 'system'){
                show = false;
            }else{
                show = ( item.VolumeStatus === 'available' || item.VolumeStatus === 'in-use')  && !over && item.InstanceType !== 'epc';
            }
        }
        if(type === 'changeProjects'){
            if(item.OriginalCategory && item.OriginalCategory === 'system'){
                show = false;
            }else{
                show = item.VolumeCategory === 'data' && item.VolumeStatus === 'available';
            }
        }
        if(type === 'delete'){
            if(item.OriginalCategory && item.OriginalCategory === 'system'){
                show = false;
            }else{
                show = item.VolumeCategory === 'data' && ((item.VolumeStatus === 'available' && item.ChargeType !== 'Monthly') || item.VolumeStatus === 'error');
            }
        }
        if(type === 'modifyProperties'){
            if(item.OriginalCategory && item.OriginalCategory === 'system'){
                show = false;
            }else{
                show = item.VolumeCategory === 'data' && item.ChargeType !== 'Monthly' && item.VolumeStatus === 'in-use'  && item.InstanceType !== 'kci';
            }
        }
        if(type === 'setSnapshot'){
            const statusValid = _.includes(['available', 'in-use'], item.VolumeStatus);

            if (statusValid) {
                show = item.VolumeStatus === 'in-use' ? item.InstanceType !== 'epc' : true;
            } else {
                show = false;
            }
        }
        if(type === 'createSnapshot'){
            show = item.VolumeType && _.includes(['available', 'in-use'], item.VolumeStatus);
            item.VolumeStatus === 'in-use'  ? show = item.InstanceType !== 'epc' : '';
        }
        if(type === 'changeBilltype'){
            if(item.OriginalCategory && item.OriginalCategory === 'system'){
                show = false;
            }else{
                show = item.VolumeType && item.VolumeCategory === 'data' &&  item.InstanceTradeType !== 2;
            }
            show = show ? _.includes(['available', 'in-use'], item.VolumeStatus) : show;
        }
        if(type === 'changeVolumeType'){
            show = _.includes(['SSD3.0', 'EHDD'], item.VolumeType) && _.includes(['available', 'in-use'], item.VolumeStatus);
        }
        return show;
    },
    snapshotAction:function(type, item){
        let show = false;
        if(type === 'assembledImage'){
            show = item.SnapshotStatus !== 'available' ||  item.VolumeCategory !== 'system' || (item.VolumeType !=='SSD3.0' && item.SnapshotType === 'LocalSnapShot');
        }
        if(type === 'rollbackSnapshot'){
            show = item.SnapshotStatus !== 'available' ||  !(item.VolumeStatus==='available' ||  item.VolumeStatus==='in-use') || item.CopyFrom || (item.VolumeSnapshotTask && item.VolumeSnapshotTask === 'during');
        }
        if(type === 'setdeleteTime'){
            show = ['available', 'error'].indexOf(item.SnapshotStatus) === -1 ||  item.ImageRelated;
        }
        if(type === 'deleteSnapsho'){
            show = ['available', 'error'].indexOf(item.SnapshotStatus) === -1 || item.ImageRelated;
        }
        if(type === 'copySnapshot'){
            show = item.SnapshotStatus !== 'available' || item.SnapshotType === 'LocalSnapShot';
        }
        if(type === 'changeSnapshotType'){
            show = item.SnapshotStatus !== 'available' || item.SnapshotType === 'CommonSnapShot' || item.VolumeType.indexOf('ESSD') > -1;
        }
        return show;
    },
    searchKey:{
        VolumeId: '云硬盘ID，多个ID以丨分隔',
        VolumeName: '云盘名称',
        InstanceId: '实例ID，多个ID以丨分隔',
        AutoSnapshotPolicyId: '策略ID，多个ID以丨分隔',
        Tag: '云硬盘标签',
        VolumeIdList: ' ',
        SnapshotId: '快照ID',
        SnapshotName: '快照名称'
    },
    //essd云盘类型
    ESSDTypeMap: function (value, isCreateOptimised) {
        return utils.keyMap({
            ESSD_PL1: 'ESSD_SYSTEM_PL1',
            ESSD_PL2: 'ESSD_SYSTEM_PL2',
            ESSD_PL0: 'ESSD_SYSTEM_PL0'
        })(value);
    },
    tableGroup:function (value) {
        return utils.keyMap({
            volumeStatus: [
                // {label: _$('全部'),value: 'all'},
                {label: _$('创建中'),value: 'creating'},
                {label: _$('待挂载'), value: 'available'},
                {label: _$('挂载中'),value: 'attaching'},
                {label: _$('使用中'),value: 'in-use'},
                {label: _$('卸载中'),value: 'detaching'},
                {label: _$('扩容中'),value: 'extending'},
                {label: _$('删除中'),value: 'deleting'},
                {label: _$('回收中'),value: 'recycling'},
                {label: _$('错误'),value: 'error'},
                {label: _$('类型变更中'),value: 'type_changing'}
            ],
            volumeType: [
                // {label: _$('全部'), value: 'all'},
                {label: _$('云硬盘3.0（SSD）'), value: 'SSD3.0'},
                {label: _$('高效云盘'), value: 'EHDD'},
                {label: _$('极速云盘(PL0)'), value: 'ESSD_PL0'},
                {label: _$('极速云盘(PL1)'), value: 'ESSD_PL1'},
                {label: _$('极速云盘(PL2)'), value: 'ESSD_PL2'},
                {label: _$('极速云盘(PL3)'), value: 'ESSD_PL3'}
            ],
            ChargeType: [
                // {label: _$('全部'), value: 'all'},
                {label: _$('包年包月'), value: 1},
                {label: _$('按量付费（按日月结）'), value: 5},
                {label: _$('按量付费（按小时月结）'), value:84},
                {label: _$('按量付费'), value: 87},
                {label: _$('按日配置付费(试用)'), value: 52}, // 实际 value 为 5
                {label: _$('竞价型实例'), value: 810}
            ],
            volumeCategory: [
                // {label: _$('全部'), value: 'all'},
                {label: _$('数据盘'), value: 'data'},
                {label: _$('系统盘'), value: 'system'}
            ],
            snapshotType: [
                {label: _$('全部'), value: 'all'},
                {label: _$('普通快照'), value: 'CommonSnapShot'},
                {label: _$('极速可用快照'), value: 'LocalSnapShot'}
            ],
            expireStatus: [
                // {label: _$('全部'), value: 'all'},
                {label: _$('即将到期'), value: 'expiring-soon'},
                {label: _$('已到期'), value: 'expired'}
            ],
            availableZone: {
                'cn-shanghai-3': [
                    // {
                    //     label: _$('全部'),
                    //     value: 'all'
                    // },
                    {
                        value: 'cn-shanghai-3a',
                        label: _$('可用区A')
                    },
                    {
                        value: 'cn-shanghai-3b',
                        label: _$('可用区B')
                    }
                ],
                'cn-beijing-6': [
                    // {
                    //     label: _$('全部'),
                    //     value: 'all'
                    // },
                    {
                        value: 'cn-beijing-6a',
                        label: _$('可用区A')
                    },
                    {
                        value: 'cn-beijing-6b',
                        label: _$('可用区B')
                    },
                    {
                        value: 'cn-beijing-6c',
                        label: _$('可用区C')
                    },
                    {
                        value: 'cn-beijing-6d',
                        label: _$('可用区D')
                    },
                    {
                        value: 'cn-beijing-6e',
                        label: _$('可用区E')
                    }
                ],
                'cn-shanghai-2': [
                    // {
                    //     label: _$('全部'),
                    //     value: 'all'
                    // },
                    {
                        value: 'cn-shanghai-2a',
                        label: _$('可用区A')
                    },
                    {
                        value: 'cn-shanghai-2b',
                        label: _$('可用区B')
                    },
                    {
                        value: 'cn-shanghai-2d',
                        label: _$('可用区D')
                    }
                ],
                'eu-east-1': [
                    // {
                    //     label: _$('全部'),
                    //     value: 'all'
                    // },
                    {
                        value: 'eu-east-1a',
                        label: _$('可用区A')
                    },
                    {
                        value: 'eu-east-1b',
                        label: _$('可用区B')
                    }
                ],
                'ap-singapore-1': [
                    // {
                    //     label: _$('全部'),
                    //     value: 'all'
                    // },
                    {
                        value: 'ap-singapore-1b',
                        label: _$('可用区B')
                    },
                    {
                        value: 'ap-singapore-1c',
                        label: _$('可用区C')
                    }
                ],
                'cn-taipei-1': [
                    // {
                    //     label: _$('全部'),
                    //     value: 'all'
                    // },
                    {
                        value: 'cn-taipei-1a',
                        label: _$('可用区A')
                    }
                ],
                'cn-qingdao-1': [
                    // {
                    //     value: 'all',
                    //     label: _$('全部')
                    // },
                    {
                        value: 'cn-qingdao-1a',
                        label: _$('可用区A')
                    }
                ],
                'cn-guangzhou-1': [
                    // {
                    //     label: _$('全部'),
                    //     value: 'all'
                    // },
                    {
                        value: 'cn-guangzhou-1a',
                        label: _$('可用区A')
                    },
                    {
                        value: 'cn-guangzhou-1b',
                        label: _$('可用区B')
                    }
                ],
                'cn-beijing-fin': [
                    // {
                    //     label: _$('全部'),
                    //     value: 'all'
                    // },
                    {
                        value: 'cn-beijing-fin-a',
                        label: _$('可用区A')
                    }
                ],
                'cn-shanghai-fin': [
                    // {
                    //     label: _$('全部'),
                    //     value: 'all'
                    // },
                    {
                        value: 'cn-shanghai-fin-a',
                        label: _$('可用区A')
                    }
                ],
                'cn-north-1-gov': [
                    {
                        value: 'cn-north-1-gov-a',
                        label: _$('可用区A')
                    }
                ],
                'cn-qingdaodev-1': [
                    {
                        value: 'cn-qingdaodev-1a',
                        label: _$('可用区A')
                    }
                ],
                'cn-hongkong-2': [
                    {
                        value: 'cn-hongkong-2a',
                        label: _$('可用区A')
                    }
                ],
                'cn-northwest-1': [
                    {
                        value: 'cn-northwest-1a',
                        label: _$('可用区A')
                    }
                ],
                'cn-northwest-2': [
                    {
                        value: 'cn-northwest-2a',
                        label: _$('可用区A')
                    }
                ],
                'cn-qingyangtest-1': [
                    {
                        value: 'cn-qingyangtest-1a',
                        label: _$('可用区A')
                    },
                    {
                        value: 'cn-qingyangtest-1b',
                        label: _$('可用区B')
                    }
                ]
            }
        })(value);
    },

    rootByImage(value){
        if(value.indexOf('windows') > -1){
            return 'kingsoft';
        }
        if(value.indexOf('ubuntu') > -1){
            return 'ubuntu';
        }
        return 'root';
    }
};
export default filters;
