import { ajaxGet } from './request';
const Service = 'ebs';
const Version = '2016-03-04';
const defaultUrlParam = `/i/ebs/api/?Service=${Service}&Version=${Version}`;

export default {
    // 快照跨区域复制支持的region
    QuerySnapshotCopyRegionLimits() {
        return ajaxGet(`${defaultUrlParam}&Action=QuerySnapshotCopyRegionLimits`, {});
    },
    //获取ebs通用配置
    QueryCommonConfig(){
        return ajaxGet(`${defaultUrlParam}&Action=QueryCommonConfig`, {});
    }
};
